import React from "react"

const NotFoundPage = () => (
  <main>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </main>
)

export default NotFoundPage

export const Head = props => <title>404: Not found</title>
